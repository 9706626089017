import "./pages.css";

const Contact = () => {
    return (
        <div className="content animated fadeIn">
            Contact
            <p></p>
        </div>
    );
};

export default Contact;
